import React from 'react';
import {
  Added,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="November 2018"
      subnav="release-notes">
      <div id="November2018" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Tell your friends: Uniform is back to full strength. And to prove it,
          we’re hitting you with our first ever <em>pattern</em>. (If you
          thought it would be anything but empty states, we’re sorry, but they
          were practically covered in strobe lights, blasting a{' '}
          <Link href="http://www.instantrapairhorn.com/">hip-hop airhorn</Link>.
          Attention could not be diverted.)
        </Paragraph>
        <Paragraph>
          Once you’ve enjoyed everything those guidelines have to offer, revisit
          your favorite component and check out our brand new{' '}
          <em>live editor</em>. Depending on the component, you might also be in
          for a new prop. Only the list below would know for sure, so check it
          twice!
        </Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>Live editor support for all components.</Added>
              <Added>
                <Link href="/patterns/empty-states">Empty state</Link>{' '}
                guidelines to the brand new{' '}
                <Link href="/patterns">Patterns</Link> section.
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.22.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.22.0"
            />

            <div className={styles.changesList}>
              <Added>
                The <code>status</code> prop to our{' '}
                <Link href="/components/dialogs/alert/code">alert’s</Link>{' '}
                action shape. (Shoutout{' '}
                <Link href="https://github.com/brandoncollins92">Brandon</Link>
                .)
              </Added>
              <Added>
                The <code>hideDismiss</code> prop to{' '}
                <Link href="/components/selects/single-select/code">
                  single selects
                </Link>
                .
              </Added>
              <Added>
                Left and right orientations for an{' '}
                <Link href="/components/icon/code">icon’s</Link>{' '}
                <code>labelPosition</code>.
              </Added>
              <Added>
                Support for <Link href="/components/link/design">links</Link> to
                look like buttons in certain situations—like{' '}
                <Link href="/patterns/empty-states">empty states</Link>.
              </Added>
              <Fixed>
                The <code>qaId</code> that never was (in relation to{' '}
                <Link href="/components/selects/lookup-multi-select/code">
                  lookup multi-select
                </Link>
                ).
              </Fixed>
              <Fixed>
                A <Link href="/components/dialogs/modal/design">modal</Link>{' '}
                issue where buttons rendered in an incorrect order. Big thanks
                to <Link href="https://github.com/ajcf">Amanda</Link>.
              </Fixed>
              <Fixed>
                The padding that remained after dismissing a{' '}
                <Link href="/components/notifications/notice/design">
                  notice
                </Link>
                . Thank you,{' '}
                <Link href="https://github.com/dwerth06">Dani</Link>!
              </Fixed>
              <Fixed>
                Overflowing pills in the{' '}
                <Link href="/components/selects/lookup-multi-select/design">
                  lookup multi-select
                </Link>
                .
              </Fixed>
              <Fixed>
                An issue where options within a group weren’t actually selected,
                specifically in{' '}
                <Link href="/components/selects/single-select/design">
                  single select
                </Link>
                .
              </Fixed>
              <Fixed>
                The occasional squished{' '}
                <Link href="/components/avatars/design">avatar</Link>.
              </Fixed>
              <Fixed>
                An incorrect color in the{' '}
                <Link href="/components/tooltip/design">tooltip</Link> tail.
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="CSS"
              version="1.11.4"
              versionUrl="https://github.com/hudl/uniform-ui-css/releases/tag/v1.11.4"
            />

            <div className={styles.changesList}>
              <Fixed>
                An issue where unchecked{' '}
                <Link href="/components/forms/checkbox/code">checkboxes</Link>{' '}
                would mysteriously white out a screen.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Fixed>Layer styles replacing icon masks.</Fixed>
              <Fixed>
                The thing where <code>default</code> was flooding icons in every
                component.
              </Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
